import { Signal } from '@angular/core';

import { AuthInfo } from '../models';

/**
 * Base class of a service that provides information about the current authentication.
 *
 * This has to be implemented and provided by the application.
 */
export abstract class AbstractAuthInfoProviderService {
  /**
   * Provides the Authentication and Authorization info of the application.
   */
  abstract readonly authInfo: Signal<AuthInfo | null>;

  /**
   * Schedule a logout.
   */
  abstract scheduleLogout(): void;
}
