/**
 * The symbol used to store the unique symbol of a permission type on a permission.
 */
export const typeSymbol = Symbol('Permission Type');

/**
 * The symbol used to store the numeric value on a permission.
 */
export const valueSymbol = Symbol('Permission Value');

/**
 * The symbol used to store the metadata on a permission type.
 */
export const metadataSymbol = Symbol('Permission Metadata');
