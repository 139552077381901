import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';

import type { RegisteredSettings, RegisteredSettingsFormPlugin } from '@mp/kernel/settings/data-access';

import { ORGANIZATION_SETTINGS_TOKEN } from '../injection-tokens';

/**
 * Registeres a module's organization settings for editing in the UI.
 */
export function provideModuleOrganizationSettings(
  moduleName: string,
  ...settingsPluginFactories: (() => RegisteredSettingsFormPlugin)[]
): EnvironmentProviders {
  return makeEnvironmentProviders(
    settingsPluginFactories.map((factory) => ({
      provide: ORGANIZATION_SETTINGS_TOKEN,
      useFactory: () =>
        ({
          moduleName,
          settings: factory(),
        }) as RegisteredSettings,
      multi: true,
    })),
  );
}
