import { Injectable, Signal, computed } from '@angular/core';

import { OrganizationContext, PermissionContext, UserContext } from '@mp/kernel/auth/domain';
import { PermissionsService } from '@mp/kernel/permissions/data-access';
import { Permission } from '@mp/kernel/permissions/domain';

import { AuthInfo } from '../models';

import { AbstractAuthInfoProviderService } from './abstract-auth-info-provider.service';

/**
 * Service to access the current LoginContext.
 */
@Injectable({ providedIn: 'root' })
export class LoginContextService {
  private readonly existingAuthInfo: Signal<AuthInfo> = computed(() => {
    const authInfo = this.providerService.authInfo();
    if (!authInfo) {
      throw new Error('Unauthorized');
    }
    return authInfo;
  });

  constructor(
    private readonly providerService: AbstractAuthInfoProviderService,
    private readonly permissionsService: PermissionsService,
  ) {}

  /**
   * Access to the user context of the current logged in user.
   */
  public readonly userContext: Signal<UserContext> = computed(() => this.existingAuthInfo().userContext);

  /**
   * Access to the organization context of the current logged in user.
   */
  public readonly organizationContext: Signal<OrganizationContext> = computed(
    () => this.existingAuthInfo().organizationContext,
  );

  /**
   * Access to the permission context of the current logged in user.
   */
  public readonly permissionContext: Signal<PermissionContext> = computed(() => this.createPermissionContext());

  /**
   * Provides the auth token associated with the current user.
   */
  public readonly accessToken: Signal<string> = computed(() => this.existingAuthInfo().accessToken);

  /**
   * Returns a boolean that indicates whether the login is complete.
   */
  public readonly isUserLoggedIn: Signal<boolean> = computed(() => !!this.providerService.authInfo());

  /**
   * Returns a signal that returns a boolean that indicates whether the given permission is set.
   * @param permission The permission to check.
   */
  public permission(permission: Permission): Signal<boolean> {
    return computed(() => this.permissionContext().hasPermission(permission));
  }

  /**
   * Schedule a logout.
   */
  public scheduleLogout(): void {
    this.providerService.scheduleLogout();
  }

  private createPermissionContext(): PermissionContext {
    const existingPermissions = this.providerService.authInfo()?.permissions ?? {};
    return Object.freeze<PermissionContext>({
      hasPermission: (permission: Permission): boolean => {
        return this.permissionsService.checkPermission(existingPermissions, permission);
      },
    });
  }
}
