import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';

import { PERMISSIONS_TOKEN } from '../injection-tokens';
import { RegisteredPermissions, UnknownPermissionType } from '../models';

export function provideModulePermissions(
  moduleName: string,
  ...permissionTypes: UnknownPermissionType[]
): EnvironmentProviders {
  return makeEnvironmentProviders(
    permissionTypes.map((permissions) => ({
      provide: PERMISSIONS_TOKEN,
      useValue: {
        moduleName,
        permissions,
      } as RegisteredPermissions,
      multi: true,
    })),
  );
}
