import { Injectable, inject } from '@angular/core';

import {
  AvailableFeaturePermissionsDescription,
  AvailableModulePermissionsDescription,
  AvailablePermissionDescription,
  Permission,
  PermissionTypeMetadata,
  PermissionsDictionary,
  metadataSymbol,
  typeSymbol,
  valueSymbol,
} from '@mp/kernel/permissions/domain';
import { ModuleTypeDictionary } from '@mp/shared/domain';
import { ModuleInfoService } from '@mp/shared/util';

import { PERMISSIONS_TOKEN } from '../injection-tokens';
import {
  RegisteredPermissions,
  UiPermissionDescription,
  UiPermissionsDescription,
  UnknownPermissionEnumType,
  UnknownPermissionType,
} from '../models';

interface PermissionTypeInfo {
  readonly moduleName: string;
  readonly permissions: UnknownPermissionType;
  readonly metadata: PermissionTypeMetadata<UnknownPermissionEnumType>;
  readonly removeBitMasks: Map<number, number>;
}

interface AvailablePermissionTypeInfo {
  readonly moduleName: string;
  readonly featureId: string;
  readonly permissions: UiPermissionsDescription['permissions'];
}

@Injectable({ providedIn: 'root' })
export class PermissionsService {
  private readonly moduleInfoService: ModuleInfoService = inject(ModuleInfoService);
  private readonly registeredPermissions: readonly RegisteredPermissions[] = inject(PERMISSIONS_TOKEN);

  private readonly permissionTypeInfoByTypeSymbol: Map<symbol, PermissionTypeInfo> = this.buildTypeInfoMap();
  private readonly permissionTypeSymbolMap: ModuleTypeDictionary<symbol> = this.buildTypeSymbolMap();
  private removeBitMasks: ModuleTypeDictionary<Map<number, number>> = {};

  /**
   * Checks whether the given permission is set in the given permission dictionary.
   * @param effectivePermissions The effective permissions of a user etc.
   * @param permissionToCheck The permission to check.
   */
  public checkPermission(effectivePermissions: PermissionsDictionary, permissionToCheck: Permission): boolean {
    const info = this.tryGetModuleNameAndFeatureId(permissionToCheck);
    if (info == null) {
      return false;
    }

    const [moduleName, featureId] = info;
    return this.checkPermissionValue(effectivePermissions, moduleName, featureId, permissionToCheck[valueSymbol]);
  }

  /**
   * Gets the descriptions of the available permissions.
   * @param availablePermissions The available permissions as dictionary defined by the backend.
   * @returns The descriptions of the available permissions.
   */
  // eslint-disable-next-line complexity
  public getAvailablePermissionDescriptions(
    availablePermissions: ModuleTypeDictionary<UiPermissionsDescription>,
  ): readonly AvailableModulePermissionsDescription[] {
    const moduleDescriptions: AvailableModulePermissionsDescription[] = [];

    // Iterate the definitions from backend
    for (const [moduleName, moduleDict] of Object.entries(availablePermissions)) {
      const featureDescriptions: AvailableFeaturePermissionsDescription[] = [];

      for (const [featureId, featureDescription] of Object.entries(moduleDict)) {
        const typeSymbol = this.permissionTypeSymbolMap[moduleName]?.[featureId];
        // Check for a config override from the frontend
        const definedConfig = typeSymbol && this.permissionTypeInfoByTypeSymbol.get(typeSymbol)?.metadata.config;

        const permissionDescriptions: AvailablePermissionDescription[] = [];

        const permissionTypeInfo: AvailablePermissionTypeInfo = {
          moduleName,
          featureId,
          permissions: featureDescription.permissions,
        };

        for (const [field, { value, fallbackDisplayName }] of this.getPermissionsByTypeInfo(permissionTypeInfo)) {
          permissionDescriptions.push({
            name: field,
            displayName: definedConfig?.displayNames?.[field] ?? fallbackDisplayName ?? field,
            contains: (permissions: PermissionsDictionary): boolean => {
              return this.checkPermissionValue(permissions, moduleName, featureId, value);
            },
            toggle: (permissions: PermissionsDictionary, toggle?: boolean): PermissionsDictionary => {
              return this.togglePermissionValue(permissions, permissionTypeInfo, value, toggle);
            },
          });
        }

        if (permissionDescriptions.length === 0) {
          continue;
        }

        featureDescriptions.push({
          featureId,
          featureDisplayName: definedConfig?.featureDisplayName ?? featureDescription.fallbackDisplayName ?? featureId,
          permissions: permissionDescriptions,
        });
      }

      if (featureDescriptions.length === 0) {
        continue;
      }

      featureDescriptions.sort((a, b) => a.featureDisplayName.localeCompare(b.featureDisplayName));

      moduleDescriptions.push({
        moduleName,
        moduleDisplayName: this.moduleInfoService.getDisplayName(moduleName),
        featureDescriptions,
      });
    }

    return moduleDescriptions.sort((a, b) => a.moduleDisplayName.localeCompare(b.moduleDisplayName));
  }

  private checkPermissionValue(
    effectivePermissions: PermissionsDictionary,
    moduleName: string,
    featureId: string,
    value: number,
  ): boolean {
    // Get the flags from the effective permissions
    const flags = effectivePermissions[moduleName]?.[featureId] ?? 0;

    return (flags & value) === value;
  }

  private togglePermissionValue(
    effectivePermissions: PermissionsDictionary,
    info: AvailablePermissionTypeInfo,
    value: number,
    toggle?: boolean,
  ): PermissionsDictionary {
    const { moduleName, featureId } = info;

    toggle ??= !this.checkPermissionValue(effectivePermissions, moduleName, featureId, value);

    // Get the current flags from the effective permissions
    const flags = effectivePermissions[moduleName]?.[featureId] ?? 0;

    const newFlags = this[toggle ? 'addPermissionFlags' : 'removePermissionFlags'](flags, value, info);

    return {
      ...effectivePermissions,
      [moduleName]: {
        ...effectivePermissions[moduleName],
        [featureId]: newFlags,
      },
    };
  }

  private addPermissionFlags(existingFlags: number, value: number): number {
    return existingFlags | value;
  }

  private removePermissionFlags(existingFlags: number, value: number, info: AvailablePermissionTypeInfo): number {
    // When removing combined permissions, only the value of the particular permission should be removed.
    // Other including permissions should still be set. On the other hand, when removing a non-combined
    // permission, all combined permissions that are not fully set afterwards should also be removed.
    return existingFlags & ~this.getRemoveBitMask(info, value);
  }

  /**
   * Returns the complete bitmask to remove if a permission value is removed. This includes
   * bits of permissions that are not fully included anymore.
   */
  private getRemoveBitMask(info: AvailablePermissionTypeInfo, value: number): number {
    const { moduleName, featureId } = info;

    const removeBitMasksMap = this.removeBitMasks[moduleName]?.[featureId] ?? new Map<number, number>();

    let flags = removeBitMasksMap.get(value);

    if (flags != null) {
      return flags;
    }

    // Find the relevant bit. Use the entire value if the value is a pure combination
    flags = this.getRelevantBit(info, value) || value;

    // Then check the other permissions and remove the relevant bits if those are not fully included anymore.
    // This runs multiple times as those permissions can contain further flags again.
    let previousFlags = 0;
    while (previousFlags !== flags) {
      previousFlags = flags;
      let flagsToRemove = 0;

      for (const [, { value: permissionValue }] of this.getPermissionsByTypeInfo(info)) {
        if (permissionValue > flags && (permissionValue & flags) > 0) {
          flagsToRemove |= this.getRelevantBit(info, permissionValue);
        }
      }

      flags |= flagsToRemove;
    }

    this.removeBitMasks = {
      ...this.removeBitMasks,
      [moduleName]: {
        ...this.removeBitMasks[moduleName],
        [featureId]: removeBitMasksMap.set(value, flags),
      }
    };

    return flags;
  }

  /**
   * Returns the "relevant"/"unique" bit of a permission value.
   * This is the bit that is not included in any other permissions of the type.
   */
  private getRelevantBit(info: AvailablePermissionTypeInfo, value: number): number {
    let flags = value;

    for (const [, { value: permissionValue }] of this.getPermissionsByTypeInfo(info)) {
      // value itself is not considered
      if (permissionValue === value) {
        continue;
      }

      if ((permissionValue & value) === permissionValue) {
        flags &= ~permissionValue;
      }
    }

    // This can also be 0, then the value does not contain a uniqe permission but is just a combination.
    return flags;
  }

  private tryGetModuleNameAndFeatureId(permission: Permission): [moduleName: string, featureId: string] | null {
    const info = this.permissionTypeInfoByTypeSymbol.get(permission[typeSymbol]);

    // The permission is not registered/available
    if (info == null) {
      return null;
    }

    const {
      moduleName,
      metadata: {
        config: { featureId },
      },
    } = info;

    return [moduleName, featureId];
  }

  private buildTypeInfoMap(): Map<symbol, PermissionTypeInfo> {
    const map = new Map<symbol, PermissionTypeInfo>();

    for (const { moduleName, permissions } of this.registeredPermissions) {
      map.set(permissions[typeSymbol], {
        moduleName,
        permissions,
        metadata: permissions[metadataSymbol],
        removeBitMasks: new Map(),
      });
    }

    return map;
  }

  private buildTypeSymbolMap(): ModuleTypeDictionary<symbol> {
    let result: ModuleTypeDictionary<symbol> = {};

    for (const { moduleName, permissions } of this.registeredPermissions) {
      result = {
        ...result,
        [moduleName]: {
          ...result[moduleName],
          [permissions[metadataSymbol].config.featureId]: permissions[typeSymbol],
        },
      };
    }

    return result;
  }

  private *getPermissionsByTypeInfo(
    info: AvailablePermissionTypeInfo,
  ): Iterable<[keyof UnknownPermissionEnumType & string, UiPermissionDescription]> {
    for (const [propertyName, description] of Object.entries(info.permissions)) {
      if (description == null || description.value === 0) {
        continue;
      }

      const field = propertyName as keyof UnknownPermissionEnumType & string;

      yield [field, description];
    }
  }
}
